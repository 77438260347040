interface DrupalUser {
  fetched: boolean
  accessToolbar: boolean
}

type UseDrupalUser = {
  accessToolbar: ComputedRef<boolean>
}

export default async function (): Promise<UseDrupalUser> {
  const state = useState<DrupalUser>('drupalUser', () => ({
    fetched: false,
    accessToolbar: false,
  }))

  // If the config has already been fetched, return.
  // This is the case when we're client-side, because the config is already
  // shipped via payload from the server.
  if (state.value.fetched || import.meta.server) {
    state.value.fetched = true
  }

  // This composable might be used in a middleware, where plugins have not yet
  // been executed, including the nuxt-graphql-middleware plugin.
  // But this is fine, because for this request specifically we don't need any
  // of the things that the plugin is doing (such as adding the language as a
  // query param).
  if (import.meta.client && !state.value.fetched) {
    // Load the information about the Drupal user.
    const { data } = await useGraphqlQuery('drupalUser')
    state.value.fetched = true
    if (data && data.currentDrupalUser) {
      state.value.accessToolbar = data.currentDrupalUser.canAccessToolbar
    }
  }

  const accessToolbar = computed(() => state.value.accessToolbar)

  return {
    accessToolbar,
  }
}
